import React, { useEffect } from 'react';
import Phaser from 'phaser';
import GesturesPlugin from 'phaser3-rex-plugins/plugins/gestures-plugin';

import Main from './scenes/Main';

import { getGameSize } from './utils';

const Game = () => {
  useEffect(() => {
    const config: Phaser.Types.Core.GameConfig = {
      ...getGameSize(),
      physics: { default: 'arcade' },
      scale: {
        parent: 'game',
        mode: Phaser.Scale.WIDTH_CONTROLS_HEIGHT,
        autoCenter: Phaser.Scale.CENTER_HORIZONTALLY
      },
      transparent: true,
      type: Phaser.AUTO,
      plugins: {
        scene: [{
          key: 'rexGestures',
          plugin: GesturesPlugin,
          mapping: 'rexGestures'
        }],
      },
      audio: { noAudio: true }
    };

    const game = new Phaser.Game(config);
    game.scene.add('Main', Main, true);

    return () => {
      game.destroy(true);
    };
  }, []);

  return <div id='game'></div>;
};

export default Game;
