import { Scene } from 'phaser';

import UI from './UI';
import GameMap from './Map';

import { EVENTS, isPortrait } from '../utils';

class Main extends Scene {
  constructor() {
    super('Main');
  }

  preload() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.load.image('tiles', 'Tiles_Dark.png');
      this.load.image('button', 'Button_Dark.png');
      this.load.spritesheet('Bean', 'Bean_Dark.png', { frameWidth: 64, frameHeight: 64 });
      this.load.spritesheet('Nope', 'Nope_Dark.png', { frameWidth: 64, frameHeight: 64 });
      this.load.spritesheet('Flame', 'Flame_Light.png', { frameWidth: 64, frameHeight: 64 });
    } else {
      this.load.image('tiles', 'Tiles_Light.png');
      this.load.image('button', 'Button_Light.png');
      this.load.spritesheet('Bean', 'Bean_Light.png', { frameWidth: 64, frameHeight: 64 });
      this.load.spritesheet('Nope', 'Nope_Light.png', { frameWidth: 64, frameHeight: 64 });
      this.load.spritesheet('Flame', 'Flame_Dark.png', { frameWidth: 64, frameHeight: 64 });
    }
    if (isPortrait()) {
      this.load.tilemapTiledJSON('Map', 'VerticalMap.json');
    } else {
      this.load.tilemapTiledJSON('Map', 'HorizontalMap.json');
    }
  }

  create() {
    this.initAnimations();

    this.scene.add('Game', GameMap, true) as Scene;
    this.scene.add('UI', UI, true);

    this.game.events.on(EVENTS.RESTART_GAME, () => {
      this.scene.launch('Game', { autostart: true });
    });
  }

  initAnimations() {
    this.anims.create({
      key: 'bean-walk',
      frames: this.anims.generateFrameNumbers('Bean', { start: 0, end: 2 }),
      frameRate: 6,
      repeat: -1
    });
    this.anims.create({
      key: 'nope-walk',
      frames: this.anims.generateFrameNumbers('Nope', { start: 0, end: 2 }),
      frameRate: 6,
      repeat: -1
    });
    this.anims.create({
      key: 'flame-loop',
      frames: this.anims.generateFrameNumbers('Flame', { start: 0, end: 5 }),
      frameRate: 10,
      repeat: -1
    });
  }
}

export default Main;
