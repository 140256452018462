import { Physics, Scene, Tilemaps } from 'phaser';

import Bean from '../prefabs/Bean';
import Nope from '../prefabs/Nope';
import Controller from '../prefabs/Controller';

import { EVENTS, isPortrait, uiHeight } from '../utils';

const ACTIVE_TILE = 1;

class GameMap extends Scene {
  player: Bean;

  map: Tilemaps.Tilemap
  collider: Tilemaps.TilemapLayer;
  points: Tilemaps.TilemapLayer;

  emptyTiles: Tilemaps.Tile[] = [];

  create(data: {}) {
    this.map = this.make.tilemap({ key: 'Map' });
    this.map.addTilesetImage('tiles', 'tiles');

    this.collider = this.map.createLayer('Walls', 'tiles', 0, uiHeight) as Tilemaps.TilemapLayer;
    this.collider.setCollisionByExclusion([-1]);

    if ('autostart' in data && data.autostart === true) {
      this.emptyTiles = [];
      this.startGame();
  } else {
      this.game.events.once(EVENTS.START_GAME, this.startGame.bind(this));
      this.game.events.on(EVENTS.SPAWN_NOPE, this.spawnNope.bind(this));
    }
  }

  startGame() {
    this.map.createLayer('Spawn', 'tiles', 0, uiHeight);
    this.points = this.map.createLayer('Points', 'tiles', 0, uiHeight) as Tilemaps.TilemapLayer;

    this.player = new Bean(this);
    this.add.existing(this.player);

    this.scene.add('Controller', Controller, true, { player: this.player }) as Scene;

    this.physics.add.collider(this.player, this.collider);
    this.physics.add.overlap(this.player, this.points);

    this.map.setTileIndexCallback([ACTIVE_TILE], this.pointOverlap, this, 'Points');
  }

  pointOverlap(bean: Bean, tile: Tilemaps.Tile) {
    if ('empty' in tile.properties && tile.properties.empty === false) {
      tile.properties.empty = true;
      tile.visible = false;
      this.emptyTiles.push(tile);
      this.game.events.emit(EVENTS.EAT_POINT);

      if (this.emptyTiles.length > 50) {
        const hydrate = this.emptyTiles.shift() as Tilemaps.Tile;
        this.hydrateTile(hydrate);
      }
    }
  }

  spawnNope() {
    const idx = Math.floor(Math.random() * 25);

    const tile = this.emptyTiles.splice(idx + 10, 1)[0];
    this.hydrateTile(tile);

    const nope = new Nope(this, tile.x, tile.y);
    this.add.existing(nope);

    this.physics.add.overlap(nope, this.player, this.gameOver.bind(this));
  }

  spawnFlame() {}

  hydrateTile(tile: Tilemaps.Tile) {
    if (tile) {
      tile.properties.empty = false;
      tile.visible = true;
    }
  }

  gameOver() {
    this.game.events.emit(EVENTS.GAME_OVER);
    this.game.events.off(EVENTS.SPAWN_NOPE, this.spawnNope.bind(this));
    this.player.destroy();
    this.scene.remove('Controller');
  }
}

export default GameMap;
