import { Physics, Scene } from 'phaser';

import { uiHeight } from '../utils';

type Direction = 'left' | 'right' | 'up' | 'down';
const VELOCITY = 200;

class Bean extends Physics.Arcade.Sprite {
  loaded: boolean;

  constructor(scene: Scene) {
    const calcX = 96;
    const calcY = 96 + uiHeight;
    super(scene, calcX, calcY, 'Bean');


    scene.physics.add.existing(this);
    this.setDamping(false);

    this.play('bean-walk');
    this.setCircle(28, 4, 4);
    this.setVelocityX(VELOCITY);

    this.move = this.move.bind(this);
  }


  move(direction: Direction) {
    switch (direction) {
      case 'left':
        this.flipX = true;
        this.setVelocityX(-VELOCITY);
        break;
      case 'right':
        this.flipX = false;
        this.setVelocityX(VELOCITY);
        break;
      case 'up':
        this.setVelocityY(-VELOCITY);
        break;
      case 'down':
        this.setVelocityY(VELOCITY);
        break;
    }
  }
}

export default Bean;
