import { GameObjects, Scene, Types } from 'phaser';

import { EVENTS, getGameSize, uiHeight } from '../utils';

const min = 300;
const {x, y} = getCenter();

const light: number = 0xb0b77f;
const dark: number = 0x444427;

class UI extends Scene {
  color: number;
  bg: number;
  score: number = 0;
  scoreDisplay: GameObjects.Text;
  highscore: number = parseInt(localStorage.getItem('HIGHSCORE') || `0`);
  highscoreDisplay: GameObjects.Text;

  preload() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.color = light;
      this.bg = dark;
    } else {
      this.color = dark;
      this.bg = light;
    }
  }

  create() {
    const startButton = this.make.nineslice(buttonConfig({x, y: y - 32})).setInteractive();
    const startText = this.addText({x, y: y - 32, text: 'PLAY', color: this.bg});

    startButton.on('pointerup', () => {
      startButton.destroy();
      startText.destroy();
      this.game.events.emit(EVENTS.START_GAME);
    });

    let scoreX = 48;
    if (this.highscore > 0) {
      this.highscoreDisplay = this.addText({ x: scoreX, y: 48, size: 40, text: `HIGHSCORE: ${this.highscore}`, center: false });
      scoreX += ((40 * .4) * (`${this.highscore}`.length + 13))
    }
    this.scoreDisplay = this.addText({ x: scoreX, y: 48, size: 40, text: `SCORE: ${this.score}`, center: false })
    this.game.events.on(EVENTS.EAT_POINT, this.eatPoint.bind(this));

    this.game.events.on(EVENTS.GAME_OVER, this.gameOver.bind(this));
  }

  eatPoint() {
    this.score += 10;
    this.scoreDisplay.setText(`SCORE: ${this.score}`);

    if (this.score > min) {
      const triggerScore = this.score - min;
      if (triggerScore % 450 === 0) this.game.events.emit(EVENTS.SPAWN_FLAME);
      else if (triggerScore % 300 === 0) this.game.events.emit(EVENTS.SPAWN_NOPE);
    }
  }

  gameOver() {
    const container = this.gameOverPanel();

    const restartButton = this.make.nineslice(buttonConfig({x, y: 616, width: 128 * 4.5})).setInteractive();
    container.addAt(restartButton, 1);

    restartButton.on('pointerup', () => {
      container.destroy();
      this.score = 0;
      this.game.events.emit(EVENTS.RESTART_GAME);

      if (!this.highscoreDisplay) {
        this.highscoreDisplay = this.addText({ x: 48, y: 48, size: 40, text: `HIGHSCORE: ${this.highscore}`, center: false });
      } else {
        this.highscoreDisplay.setText(`HIGHSCORE: ${this.highscore}`);
      }
      this.scoreDisplay.x = 48 + getHighscoreDisplayWidth(this.highscore);
    });
  }

  gameOverPanel(): GameObjects.Container {
    const text = `${this.score}`;
    localStorage.setItem('HIGHSCORE', text);

    let newHighscore = false;
    if (this.score > this.highscore) {
      this.highscore = this.score;
      newHighscore = true;
    }

    const container = this.add.container(0, 0, [
      this.add.rectangle(x, y, (x * 2) - 128, y * 2 - 256, this.bg, .95),
      this.addText({x, y: 300, text: 'GAME OVER', size: 96}),
      this.addText({x, y: 390, text: newHighscore ? 'NEW HIGHSCORE!' : 'FINAL SCORE', size: 44}),
      this.addText({x, y: 458, text, size: 128}),
      // this.addText({x, y: 452, text: 'HIGH SCORES'}),
      this.addText({x, y: 616, text: 'PLAY AGAIN', color: this.bg}),
    ]);

    return container;
  }

  addText({x, y, text, color = this.color, size = 64, center = true }: { x: number, y: number, text: string, color?: number, size?: number, center?: boolean }) {
    const calcX = center ? x - ((size * .4) * (text.length / 2)) : x;
    return this.add.text(calcX, y - (size / 2), text, { fontSize: size, fontFamily: '"VT323", sans-serif', color: convertColor(color) })
  }
}

function convertColor(color: number): string {
  return `#${color.toString(16)}`;
}

function buttonConfig(data: Types.GameObjects.NineSlice.NineSliceConfig): Types.GameObjects.NineSlice.NineSliceConfig {
  return {
    key: 'button',
    width: 256 * 2,
    height: 256,
    leftWidth: 128,
    rightWidth: 128,
    topHeight: 128,
    bottomHeight: 128,
    add: true,
    scale: 1,
    ...data,
  }
}

function getCenter() {
  const { width, height } = getGameSize();
  const x = width / 2;
  const y = ((height + uiHeight) / 2);
  return { x, y };
}

function getHighscoreDisplayWidth(highscore: number, size: number = 40) {
  return ((size * .4) * (`${highscore}`.length + 13))
}

export default UI;
