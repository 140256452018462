import { Scene } from 'phaser';
import GesturesPlugin from 'phaser3-rex-plugins/plugins/gestures-plugin';

import Bean from '../prefabs/Bean';

import { EVENTS } from '../utils';

class Controller extends Scene {
  player: Bean;
  swipe: GesturesPlugin.Swipe;

  create(data: { player: Bean }) {
    this.player = data.player;
    this.keyboardCallback = this.keyboardCallback.bind(this);
    this.swipeCallback = this.swipeCallback.bind(this);

    if (this['rexGestures']) {
      this.swipe = this['rexGestures'].add.swipe(this, {
        enable: true,
        dir: '4dir'
      });
    }

    this.initControls();
    this.game.events.on(EVENTS.GAME_OVER, this.endGame.bind(this));
  }

  endGame() {
    this.input.keyboard?.removeAllListeners();
    this.swipe.off('swipe', this.swipeCallback);
  }

  initControls() {
    // Arrow Pad
    this.input.keyboard?.on('keydown-UP', this.keyboardCallback);
    this.input.keyboard?.on('keydown-LEFT', this.keyboardCallback);
    this.input.keyboard?.on('keydown-DOWN', this.keyboardCallback);
    this.input.keyboard?.on('keydown-RIGHT', this.keyboardCallback);

    // WASD
    this.input.keyboard?.on('keydown-W', this.keyboardCallback);
    this.input.keyboard?.on('keydown-A', this.keyboardCallback);
    this.input.keyboard?.on('keydown-S', this.keyboardCallback);
    this.input.keyboard?.on('keydown-D', this.keyboardCallback);

    // Touch
    this.swipe.on('swipe', this.swipeCallback);
  }

  keyboardCallback({ key }: KeyboardEvent) {
    if (key === 'ArrowUp' || key === 'w') this.player.move('up');
    else if (key === 'ArrowDown' || key === 's') this.player.move('down');
    else if (key === 'ArrowLeft' || key === 'a') this.player.move('left');
    else if (key === 'ArrowRight' || key === 'd') this.player.move('right');
  }

  swipeCallback(swipe) {
    if (swipe.left) this.player.move('left');
    else if (swipe.right) this.player.move('right');
    else if (swipe.up) this.player.move('up');
    else if (swipe.down) this.player.move('down');
  }
}

export default Controller;
