const buffer = 250;
export const uiHeight = 48;

const width = window.innerWidth;
const height = window.innerHeight;

export function isPortrait() {
    return height > (width + buffer);
}

export function getGameSize() {
    const vertical = { height: 1088 + uiHeight, width: 640 };
    const horizontal = { height: 1088 + uiHeight, width: 1664 };

    if (isPortrait()) return vertical;
    else return horizontal;
}

export const EVENTS = {
    OPEN_TUTORIAL: 'OPEN_TUTORIAL',
    START_GAME: 'START_GAME',
    RESTART_GAME: 'RESTART_GAME',
    GAME_OVER: 'GAME_OVER',
    EAT_POINT: 'EAT_POINT',
    SPAWN_NOPE: 'SPAWN_NOPE',
    SPAWN_FLAME: 'SPAWN_FLAME',
};
