import React from 'react';
import getYear from 'date-fns/getYear';

import Game from './game/Game';

import './styles/styles.scss';


const App = () => {
    return <>
        <center><h1 id="logo"><span style={{ opacity: 0 }}>Julia M Lim</span></h1></center>
        <center><h2 className="title">Full Stack Developer</h2></center>
        <Game/>
        <footer>
            <center>Julia Lim &copy; {getYear(new Date())}</center>
        </footer>
    </>;
};

export default App;
