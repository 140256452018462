import { Physics, Scene } from 'phaser';

import { uiHeight } from '../utils';

class Nope extends Physics.Arcade.Sprite {
  /**
   * @param x Grid coordinate
   * @param y Grid coordinate
   */
  constructor(scene: Scene, x: number, y: number) {
    const calcX = 32 + (x * 64);
    const calcY = 32 + (y * 64) + uiHeight;
    super(scene, calcX, calcY, 'Nope');

    scene.physics.add.existing(this);

    this.play('nope-walk');
    this.setCircle(22, 10, 10);
  }
}

export default Nope;
